<template>
  <header class="kb-header" id="kb-header-v2">
    <div class="header-component">
      <nav class="footer-fnb">
        <router-link to="/main" class="logo" v-slot="{ navigate }">
          <button @click="closeMenu(navigate)"><i class="kb-logo-long"></i></button>
        </router-link>
        <ol class="fnb-list">
          <li class="fnb-item"><router-link :to="{name:'TrainNotification'}" class="fnb-link fnb-alarm"><i class="icon-alarm-v2"></i></router-link></li>
        </ol>
      </nav>
    </div>
  </header>
</template>

<script>
import {computed, nextTick, ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import {goBack} from '@/assets/js/modules/common/common';
import {pushQuery} from '@/assets/js/ui.init';

export default {
  name: 'MobileMainHeaderV2',
  props: {
    title: {type: String, default: 'HRD 클라우드'},
    placeholder: {type: String, default: 'HRD 클라우드 검색'},
    keyword: [Number, String],
    goBackFunc: Function,
    showBack: Boolean,
    showMap: Boolean,
    showSearch: Boolean,
    showSubMenu: Boolean,
    searchByQuery: {type:Boolean, default:true},
    activeSearch: {type:Boolean, default:false},
  },
  emits: ['update:keyword'],
  setup(props, {emit}) {
    const route = useRoute();
    const searchMode = ref(props.activeSearch),
          keywordEl = ref(null),
          keyword = computed(() => route.query.keyword || ''),
          localKeyword = ref(keyword.value);

    const isMenuOpen = ref(false);

    const handleSearch = async () => {
      if (keywordEl.value) {
        if (props.searchByQuery) pushQuery({keyword: localKeyword.value});
        else emit('update:keyword', localKeyword.value);
      } else {
        searchMode.value = true;
        await nextTick();
        keywordEl.value.focus();
      }
    };

    const handleGoBack = () => {
      if (props.goBackFunc instanceof Function) {
        props.goBackFunc();
      } else {
        goBack();
      }
    };

    const closeMenu = (navigate) => {
      if (isMenuOpen.value) {
        isMenuOpen.value = false;
        navigate();
      }
    }

    watch(() => searchMode.value, async () => {
      if (searchMode.value) {
        await nextTick();
        keywordEl.value.focus();
      }
    });

    return {
      searchMode, localKeyword, keywordEl,
      handleSearch, goBack, handleGoBack, closeMenu
    }
  }
};
</script>
<template>
  <HrdCloudHistoryMobileAttendance v-if="attendanceModal" v-model="attendanceModal"/>

  <main class="kb-main" id="kb-hrdcloud-v2" v-if="!showKbTubeModal && !attendanceModal">
    <MobileMainHeaderV2
        :title="renderTitle"
    >
    </MobileMainHeaderV2>
    <template v-if="showInterestInfoModal">
      <div class="container">
        <div class="title">KB스타런에서 무엇을 학습하고 싶은가요?</div>
        <div class="subtitle">관심 주제를 선택하여 KB스타런 경험을 맞춤 설정 하세요!</div>
        <div class="topics">
          <div v-for="(item,idx) in interestInfoItems" :key="idx" class="topic"
               :class="{'selected': item.useYn === 'Y'}" @click="topicSelected(item)">
            {{ item.interestInfoNm }}
          </div>
        </div>
      </div>
      <div class="page-buttons">
        <button class="kb-btn kb-btn-primary" style="margin-top: -50px;"
                @click="showInterestInfoModal = !showInterestInfoModal"><span class="text">설정 완료</span></button>
      </div>
    </template>
    <template v-else>
      <form class="kb-form-search-forms">
        <div class="kb-form-search-v2">
          <div class="kb-form-search-field"><input type="text" v-model.trim="search.keyword"
                                                   class="kb-form-search-input-v2"
                                                   @keydown.enter.prevent="searchKeyword(search.keyword)"
                                                   placeholder="어떤 콘텐츠를 찾으시나요?">
            <button type="button" class="kb-form-search-btn" @click="searchKeyword(search.keyword)"><i
                class="icon-search">검색</i>
            </button>
            <button v-if="search.keyword.length > 0" type="button" class="kb-form-search-delete" @click="resetSearch"><i
                class="icon-delete">삭제</i></button>
          </div>
          <div style="display: flex; overflow-x: auto; padding-top: 10px;">
          <div style="display: flex; overflow-x: auto;">
            <div @click="clickInterestSearch(-1)" style="margin-right: 5px;">
              <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                 :class="{'text-is-active': activeIdx === -1}" href="javascript:"><span
                  class="text">관심</span>
              </a>
            </div>
            <div v-for="(data,idx) in interestInfoItems.filter(x => x.useYn === 'Y')" :key="idx"
                 @click="clickInterestSearch(idx)"
                 style="margin-right: 5px;">
              <a class="kb-btn kb-btn-wide rounded-lg loadmap-btn"
                 :class="{'text-is-active': idx === activeIdx}" href="javascript:"><span
                  class="text">{{ data.interestInfoNm }}</span>
              </a>
            </div>
          </div>
          <div type="button" style="width: 35px; height: 30px; text-align: right; margin-left: auto;" @click="showInterestInfoModal = !showInterestInfoModal"><i
              class="icon-setting"></i>
          </div>
        </div>
<!--          <h4 v-if="!isSearch" class="title"-->
<!--              style="font: normal 16px/22px var(&#45;&#45;kb-font-KBFGDisplayB); padding-top: 12px;">-->
<!--            {{ session.lrnerNm }}님, 반가워요!</h4>-->
<!--          <div v-if="!isSearch"-->
<!--               style="display: flex; position: relative; justify-content: space-between; align-items: center; border: 1px solid transparent; font-size: 18px; height: 20px; overflow: hidden;">-->
<!--            <div style="font: normal 14px/1.25 var(&#45;&#45;kb-font-KBFGTextB);">이번 달은 <span-->
<!--                style="color:var(&#45;&#45;kb-red) !important; font-weight: bold; font-size: 14px;"> {{ attendanceCnt }}일째</span>-->
<!--              출석중이예요!-->
<!--            </div>-->
<!--            <div style="font: normal 14px/1.25 var(&#45;&#45;kb-font-KBFGTextB);" @click="attendanceModal = !attendanceModal">-->
<!--              더보기 >-->
<!--            </div>-->
<!--          </div>-->

          <template v-if="!isSearch">
            <div v-if="schedules.length > 0" @click="clickMove(schedules[0])"
                 style="display: flex; position: relative; justify-content: space-between; align-items: baseline; border: 1px solid transparent; font-size: 18px; height: 20px; margin: 15px 0;">
              <h2 class="title">오늘</h2>
              <div style="font: normal 14px/1.25 var(--kb-font-KBFGTextB); overflow:hidden;text-overflow:ellipsis;white-space:nowrap; width: 50%;">{{schedules[0].objNm}}</div>
              <div style="font: normal 14px/18px var(--kb-font-KBFGTextB); color: var(--kb-gold);">{{schedules[0].lrnTime}}</div>
            </div>
            <div v-else
                 style="display: flex; position: relative; justify-content: space-between; align-items: start; border: 1px solid transparent; font-size: 18px; height: 20px; margin: 15px 0;">
              <h2 class="title">오늘</h2>
              <div style="font: normal 14px/18px var(--kb-font-KBFGTextB); overflow:hidden;text-overflow:ellipsis;white-space:nowrap; width: 85%;">오늘 등록된 일정이 없어요.</div>
            </div>
          </template>
        </div>
      </form>

    </template>

    <article v-if="isKbBank && !hrdLoading && !showInterestInfoModal">

      <div class="main-content main-component" style="padding-left: 15px;">
        <article v-if="hrdShorts.length > 0" class="content-section">
          <header class="section-header"><h4 class="title">숏클</h4></header>
          <HrdCloudMobileSwiper :items="hrdShorts" :session="session" :show-put="true" :is-shorts="true"
                                :addShortsItems="addShortsItems" :swipeLoading="shortsSwipeLoading"/>
        </article>
      </div>

      <HrdCloudMobileSearchResultV2 v-if="hrdCloudItems.length > 0"
                                    :keyword="tmpKeyword"
                                    :session="session"
                                    :hrds="hrdCloudItems.slice(0, 5)"
      />

<!--      <article v-if="isKbBank && sslitems.length > 0" class="content-section section-hrdcloud">-->
<!--        <header class="section-header" style="margin-left: 15px;">-->
<!--          <h2 class="title">SSL 학습노트</h2>-->
<!--        </header>-->
<!--        <div v-if="sslitems.length > 0" class="hrdcloud-list-wrapper">-->
<!--          &lt;!&ndash; board-list &ndash;&gt;-->
<!--          <SSLNoteCard :slides-per-view='"auto"'-->
<!--                       :sslItems="sslitems"-->
<!--                       :addSSLItems="addSSLItems"-->
<!--                       :swipeLoading="sslSwipeLoading"/>-->
<!--        </div>-->
<!--      </article>-->
      <div class="main-content main-component mt-2" style="padding-left: 15px;">
        <article v-if="isKbBank" class="content-section section-hrdcloud">
            <header class="section-header d-flex">
              <h4 class="title">SSL Studio</h4>
              <div class="tabs">
                <a v-for="(item, idx) in sslList" :key="idx" href="javascript:" class="tab"
                   :class="{'is-active' : sslCardFilter === item.name}" @click="sslCardFilter = item.name">
                  {{ item.title }}
                </a>
              </div>
            </header>
            <!-- hrdcloud-list-wrapper -->
            <div class="hrdcloud-list-wrapper">
              <template v-if="sslLoading">
                <LoadingDiv v-model="sslLoading"/>
              </template>
              <template v-else>
                <!-- board-list -->
                <template v-if="sslitems.length > 0">
                  <SSLNoteCard :slides-per-view='"auto"'
                               :sslItems="sslitems"/>
                </template>
                <template v-else>
                  <div class="search-container">
                    <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img
                        src="../../../assets/lxp/images/common/img_empty.png" alt="">
                      <p v-if="sslCardFilter === 'recommend'" class="text">추천 SSL이 없습니다.</p>
                      <p v-if="sslCardFilter === 'excellent'" class="text">인기 SSL이 없습니다.</p>
                      <p v-else class="text">구독 SSL이 없습니다.</p>
                    </div>
                  </div>
                </template>
              </template>

            </div>

<!--            <div class="section-bottom bottom-buttons">-->
<!--              <button class="kb-btn kb-btn-outline" @click="moveSSL">-->
<!--                &lt;!&ndash;          <button class="kb-btn kb-btn-outline" @click="underConstruction">&ndash;&gt;-->
<!--                <span class="text">SSL 바로가기</span>-->
<!--              </button>-->
<!--            </div>-->
        </article>
      </div>

<!--      <div class="main-content main-component mt-2" style="padding-left: 15px;">-->
<!--        <article v-if="isKbBank" class="content-section section-hrdcloud">-->
<!--          <header class="section-header">-->
<!--            <h4 class="title">구독채널 신규</h4>-->
<!--          </header>-->
<!--&lt;!&ndash;          &lt;!&ndash; hrdcloud-list-wrapper &ndash;&gt;&ndash;&gt;-->
<!--          <div class="hrdcloud-list-wrapper">-->
<!--            <template v-if="sslSubscLoading">-->
<!--              <LoadingDiv v-model="sslSubscLoading" />-->
<!--            </template>-->
<!--            <template v-else>-->
<!--              &lt;!&ndash; board-list &ndash;&gt;-->
<!--              <template v-if="subscribeItems.length > 0">-->
<!--                <SSLNoteCard :slides-per-view='"auto"'-->
<!--                             :sslItems="subscribeItems"/>-->
<!--              </template>-->
<!--              <template v-else>-->
<!--                <div class="search-container">-->
<!--                  <div class="result-empty" style="text-align: center; margin-top: 30px; margin-bottom: 30px;"><img-->
<!--                      src="../../../assets/lxp/images/common/img_empty.png" alt="">-->
<!--                    <p class="text">구독직원의 SSL이 없습니다.</p>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </template>-->
<!--            </template>-->
<!--          </div>-->
<!--        </article>-->

<!--      </div>-->


      <HrdCloudMobileSearchResultV2 v-if="hrdCloudItems.slice(5).length > 0"
                                    :keyword="tmpKeyword"
                                    :session="session"
                                    :hrds="hrdCloudItems.slice(5)"
      />

      <article v-if="scrollLoading && hrdCloudItems.length > 0">
        <LoadingDiv v-model="scrollLoading"/>
      </article>

    </article>
    <article v-else>
      <LoadingDiv v-model="hrdLoading"/>
    </article>

    <div v-if="isKbBank && !hrdLoading && !showInterestInfoModal && hrdCloudItems.length === 0 && sslitems.length === 0"
         class="hrdcloud-list-container">
      <slot name="top"/>
      <div class="search-container">
        <div class="search-result">
          <div class="result-empty">
            <img src="@/assets/lxp/images/common/img_empty.png" alt="">
            <p class="text">검색 결과가 없습니다.</p>
          </div>
        </div>
      </div>
      <slot name="bottom"/>
    </div>

    <MobileBanerPopupModal
        v-if="popups.length > 0 && !expirationPopup && !lochref.includes('?')"
        :items="popups"
    />
  </main>
</template>

<script>
import MobileMainHeaderV2 from '@/components/main/mobile/MobileMainHeaderV2.vue';
import {computed, reactive, ref, watch} from 'vue';
import {useStore} from 'vuex';
import {
  getCateNm,
  handleActions,
  sortTyCdDcds,
  getHrdCloudList
} from '@/assets/js/modules/hrd/hrd-common';
import {
  ACT_GET_HRDCLOUD_LATEST_LIST, ACT_GET_HRDCLOUD_LIST
} from '@/store/modules/prep/prep';
import {getDcdOptions, getListFunc, pushQuery} from '@/assets/js/ui.init';
import {useRoute} from 'vue-router';
import {
  dateToDateFormat, dateToDatePart,
  getCheckItems, getItem,
  getItems, isSuccess, //, getPaging
  lengthCheck, stringCheck,
  // stringCheck,
  timestampToDateFormat, timestampToDatePart
} from '@/assets/js/util';
import {ACT_GET_HRDCLOUD_CATE_SEARCH_LIST} from '@/store/modules/hrdcloud/hrdcloud';
import {banerDivCdDcds, banerExpsrLocCdDcds, getBanerList} from '@/assets/js/modules/baner/common-baner';
import MobileBanerPopupModal from '@/components/main/mobile/MobileBanerPopupModal';
import {
  getTodayDate
} from "@/assets/js/util";
import LoadingDiv from "@/components/common/LoadingDiv.vue";
import SSLNoteCard from "@/components/main/SSLNoteCard.vue";
import {
  ACT_GET_BEST_SUBSCRIBES_NOTE_LIST,
  ACT_GET_SOC_BOARD_LIST,
  ACT_GET_SOC_CNT,
  ACT_GET_SOC_EXCELLENT_BOARD_LIST, ACT_GET_SOC_RECPOP_BOARD_LIST, ACT_GET_SOC_SUBSCRIBE_BOARD_LIST,
  // ACT_GET_SOC_SUBSCRIBE_BOARD_LIST
} from "@/store/modules/ssl/ssl-index";
import {getCrseSnToDistCrseCd} from "@/assets/js/modules/ssl/ssl-common"; //, getDistCrseCd
import {
  ACT_GET_USER_INTEREST_INFO_LIST, ACT_GET_USER_INTEREST_RECMD_INFO_LIST,
  ACT_INSERT_USER_INTEREST_INFO,
  ACT_UPDATE_USER_INTEREST_INFO
} from "@/store/modules/interest/interest";
import {useAlert} from "@/assets/js/modules/common/alert";
import {moveSSL} from '@/assets/js/modules/common/common';
import {ACT_GET_BOARD_CATE_LIST, ACT_GET_BOARD_MST} from "@/store/modules/board/board-mst";
import {getPostList} from "@/assets/js/modules/board/board-common";
import router from "@/router";
import {currentYear, sslCodes} from "@/assets/js/modules/ssl/ssl-common";
import {ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST} from "@/store/modules/history/history";
import HrdCloudHistoryMobileAttendance from "@/components/history/mobile/HrdCloudHistoryMobileAttendance.vue";
import HrdCloudMobileSearchResultV2 from '@/components/hrdcloud/mobile/HrdCloudMobileSearchResultV2';
import HrdCloudMobileSwiper from "@/components/hrdcloud/mobile/HrdCloudMobileSwiper.vue";
import {ACT_GET_LEARN_OBJECT_TODAY_LIST} from "@/store/modules/learn/learn";
import {lrnObjTyCds} from "@/assets/js/modules/learn/learn-common";

export default {
  name: 'MobileMainV2',
  components: {
    HrdCloudMobileSwiper,
    HrdCloudHistoryMobileAttendance,
    SSLNoteCard,
    HrdCloudMobileSearchResultV2,
    LoadingDiv,
    MobileMainHeaderV2,
    MobileBanerPopupModal,
  },
  setup() {
    const store = useStore(), route = useRoute();
    const session = computed(() => store.state.auth.session);
    const sortTyCdDcd = computed(() => getDcdOptions('2073'));
    const isSearching = computed(() => (route.query && route.query.keyword && route.query.keyword.length > 0));
    const isLoading = ref(true);
    const hrdLoading = ref(true);
    const sslLoading = ref(true);
    const hottipLoading = ref(true);
    const hotTipMyPreviewList = ref([]);
    const tmpHotTipItems = ref([]);
    const schedules = ref([]);

    const hrdShorts = ref([]);

    const showRoute = ref(true),
        showSubMenu = ref(false),
        showHrdCloudMap = ref(false),
        showHrdCloudCate = ref(false),
        showKbTubeModal = ref(false),
        subscriptionModal = computed(() => store.state.hrdcloud.subscribed.show),
        addModal = computed(() => store.state.hrdcloud.added.show);
    const lochref = ref(location.href);

    const activeIdx = ref(-2);

    const isKbBank = computed(() => session.value.susdyCdDcd && (session.value.susdyCdDcd === '2109001'));

    const interestInfoItems = ref([]);
    const interestRecmdInfoItems = ref([]);
    const attendanceModal = ref(false);

    const interestInfoNms = ref("");

    const showInterestInfoModal = ref(false);

    const isSearch = ref(false);

    const {showConfirm, showMessage} = useAlert();

    const sslCurrentYear = currentYear;
    const sslTarget = sslCodes[sslCurrentYear];

    let boardMst = null;
    let boardCateList = [{cateNm: '전체', boardCateSn: ''}];

    const attendanceCnt = ref(0);

    const isSearchEnd = ref(false);
    const scrollLoading = ref(false);
    const sslSwipeLoading = ref(false);
    const shortsSwipeLoading = ref(false);

    const lrnTrgtGrpMstSns = ref([]);

    Object.keys(sslTarget).forEach(k => {
      if (k !== 'trivia') {
        lrnTrgtGrpMstSns.value.push(sslTarget[k].group[0].lrnTrgtGrpMstSn);
      }
      // }
    });
    const hrdCloudItems = ref([]);
    const hrdCloudLatestItems = ref([]);

    const getHrdCloudLatestList = async () => {
      const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {...paging});
      hrdCloudLatestItems.value = hrdCloudLatestItems.value.concat(getItems(res));
      hrdCloudItems.value = hrdCloudItems.value.concat(getItems(res));
      hrdLoading.value = false;
    };

    const getInterestInfoList = async () => {
      await store.dispatch(`interest/${ACT_GET_USER_INTEREST_INFO_LIST}`, {
        lrnerId: session.value.lrnerId,
      }).then(res => {
        if (lengthCheck(res)) {
          interestInfoItems.value = getItems(res);
          interestInfoNms.value = "";
          for (const target of interestInfoItems.value) {
            if (target.useYn == 'Y') {
              interestInfoNms.value = interestInfoNms.value + "," + target.interestInfoNm;
            }
          }
          if (interestInfoNms.value == "") {
            interestInfoNms.value = "미설정";
          } else {
            interestInfoNms.value = interestInfoNms.value.substring(1);
          }
        }
      }).catch(err => {
        console.error(err)
      })
    }

    const getInterestRecmdInfoList = async () => {
      await store.dispatch(`interest/${ACT_GET_USER_INTEREST_RECMD_INFO_LIST}`, {
        lrnerId: session.value.lrnerId,
      }).then(res => {
        if (lengthCheck(res)) {
          interestRecmdInfoItems.value = getItems(res);
        }
      }).catch(err => {
        console.error(err)
      })
    }

    const getHotTipList = async (boardId) => {
      hottipLoading.value = true;
      await store.dispatch(`boardmst/${ACT_GET_BOARD_MST}`, boardId).then(async (res) => {
        boardMst = getItem(res);
        await store.dispatch(`boardmst/${ACT_GET_BOARD_CATE_LIST}`, boardMst.boardMstSn).then((res) => {
          boardCateList = boardCateList.concat(getItems(res));
        }).finally(async () => {
          await getPostList({
            boardMstSn: boardMst.boardMstSn,
            topFixYn: 'N',
            pageNo: 1,
            pageSize: 5
          }, tmpHotTipItems, null, boardCateList);
          hotTipMyPreviewList.value = tmpHotTipItems.value;
        })
      });
      hottipLoading.value = false;
    }

    const moveToBoardDtl = (item) => {
      router.push({name: 'BoardView', params: {'boardId': item.boardId, 'boardSecuKey': item.boardSecuKey}});
    }

    const topicSelected = async (item) => {
      if (item.useYn != "Y") {
        showConfirm({
          title: '관심 주제 설정 [' + item.interestInfoNm + ']',
          text: '나의 관심 주제로 설정하시겠습니까?',
          callback: async () => {
            await store.dispatch(`interest/${ACT_INSERT_USER_INTEREST_INFO}`, {
              interestInfoCdDcd: item.interestInfoCdDcd,
              interestInfoNm: item.interestInfoNm,
            }).then(res => {
              if (isSuccess(res)) {
                showMessage("설정이 완료됐습니다.");
                getInterestInfoList();
              }
            }).catch(err => {
              console.error(err)
            })
          }
        });
      } else {
        showConfirm({
          title: '관심 주제 해제 [' + item.interestInfoNm + ']',
          text: '나의 관심 주제를 해제하시겠습니까?',
          callback: async () => {
            await store.dispatch(`interest/${ACT_UPDATE_USER_INTEREST_INFO}`, {
              interestInfoCdDcd: item.interestInfoCdDcd,
            }).then(res => {
              if (isSuccess(res)) {
                showMessage("설정이 완료됐습니다.");
                getInterestInfoList();
              }
            }).catch(err => {
              console.error(err)
            })
          }
        });
      }
    }

    getInterestInfoList();
    getInterestRecmdInfoList();


    const roadmap = ref([
      {type: 'time', title: '관심', keys: ['bookLrnSec'], showDetail: true, data: 0},
      {type: 'day', title: '기업여신', keys: ['bookLrnDayCnt'], showDetail: false, data: 1},
      {type: 'memo', title: '퇴직연금', keys: ['bookMemoCnt'], showDetail: true, data: 2},
      {type: 'complete', title: '수신/카드', keys: ['bookCompleteCnt'], showDetail: false, data: 3},
    ]);

    const sslList = ref([
      { name: 'recommend', title: '추천', isLoading: true, items:[] },
      { name: 'excellent', title: '인기', isLoading: true, items:[] },
      { name: 'subscribed', title: '구독', isLoading: true, items:[] },
    ]);

    const sslitems = ref([]);

    store.dispatch(`ssl/${ACT_GET_SOC_CNT}`).then(res => {
      if (res && res.cnt === 0) {
        sslList.value[1].title = '';
      }
    });

    const modalActive = computed(() => {
      if (subscriptionModal.value || addModal.value) return true;
      else return false;
    });

    const view = reactive({
      name: 'main',
      title: 'HRD 클라우드'
    });

    // const mainList = ref([
    //   // { name: 'recommend', title: '지금 나에게 필요한 콘텐츠', params: {category1:1}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}` },
    //   {
    //     name: 'latest',
    //     title: '따끈따끈 신규 콘텐츠',
    //     params: {category1: 2},
    //     isLoading: true,
    //     items: [],
    //     url: `prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`
    //   }
    //   // { name: 'latestPopularDuty', title: '최근 직무 분야 인기 콘텐츠', params: {category1:1}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}` },
    //   // { name: 'latestPopularKnowledge', title: '최근 지식 분야 인기 콘텐츠', params: {category1:2}, isLoading: true, items:[], url: `prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}` },
    //   // { name: 'recent', title: '나의 최근 학습 콘텐츠', params: {}, isLoading: true, items:[], url:  `prep/${ACT_GET_HRDCLOUD_RECENT_LIST}` }
    // ]);


    const hrds = ref([]),
        categories = ref([]),
        tabMenu = ref(0),
        paging = reactive({
          pageNo: 1,
          pageSize: 10,
          totalCount: 0
        }),
        sslPaging = reactive({
          pageNo: 1,
          pageSize: 10,
          totalCount: 0
        }),
        shortsPaging = reactive({
          pageNo: 1,
          pageSize: 10,
          totalCount: 0
        }),
        categoriesPaging = reactive({
          pageNo: 1,
          pageSize: 9999,
          totalCount: 0
        }),
        search = reactive({
          keyword: '',
          sortTyCdDcd: '',
          category1: '',
          category2: '',
          category3: '',
          category4: '',
          category5: ''
        }),
        toggles = reactive({
          sortTyCdDcd: false
        });

    const getList = async (params) => {
      let contentsList = await getHrdCloudLatestList({...search, ...paging, ...params});
      hrds.value = getCheckItems(contentsList);
      paging.totalCount = contentsList && contentsList.paging ? contentsList.paging.totalCount : 0;
      let categoryList = await store.dispatch(`hrdcloud/${ACT_GET_HRDCLOUD_CATE_SEARCH_LIST}`, {keyword: search.keyword, ...paging});
      categories.value = getCheckItems(categoryList);
      categoriesPaging.totalCount = categoryList && categoryList.paging ? categoryList.paging.totalCount : 0;
      isLoading.value = false;
    };

    const resetSearch = () => {
      activeIdx.value = -2;
      search.keyword = '';
      sslitems.value = excellentItems.value;
      hrdCloudItems.value = hrdCloudLatestItems.value;
      hotTipMyPreviewList.value = tmpHotTipItems.value;
      isSearch.value = false;
      paging.pageNo = 1;
      sslPaging.pageNo = 1;
      shortsPaging.pageNo = 1;
      isSearchEnd.value = false;
    }

    const clickInterestSearch = (idx) => {
      if (idx === -1 && interestInfoNms.value === "미설정") {
        showConfirm('관심주제를 설정 후 사용 가능합니다.<br> 설정 페이지로 이동하시겠습니까?', () => {
          showInterestInfoModal.value = true;
        });
        return;
      }

      if (activeIdx.value === idx) {
        resetSearch();
        return;
      }

      activeIdx.value = idx;
      search.keyword = '';
      if (idx === -1) {
        searchKeyword(interestInfoNms.value.replaceAll(/[/,]/g, '|'));
      } else {
        searchKeyword(interestInfoItems.value.filter(x => x.useYn === 'Y')[idx].interestInfoNm);
        // searchKeyword(interestRecmdInfoItems.value[idx].interestInfoNm);
      }
    }

    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    // const getMainList = async () => {
    //   // let recommend = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECOMMEND_LIST}`, {pageNo: 1, pageSize: 4});
    //   // setMainItems(mainList, recommend, 0);
    //   let latest = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {pageNo: 1, pageSize: 4});
    //
    //   setMainItems(mainList, latest, 0);
    //   // let latestPopularDuty = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {
    //   //   category1: mainList.value[2].params.category1,
    //   //   pageNo: 1,
    //   //   pageSize: 4
    //   // });
    //   // setMainItems(mainList, latestPopularDuty, 2);
    //   // let latestPopularKnowledge = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_POPULAR_LIST}`, {
    //   //   category1: mainList.value[3].params.category1,
    //   //   pageNo: 1,
    //   //   pageSize: 4
    //   // });
    //   // setMainItems(mainList, latestPopularKnowledge, 3);
    //   // let recent = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_RECENT_LIST}`, {pageNo: 1, pageSize: 4});
    //   // setMainItems(mainList, recent, 4);
    // };

    const attendanceList = ref([]);

    getListFunc(`history/${ACT_GET_HRDCLOUD_HISTORY_ATTENDANCE_LIST}`, {year: new Date().getFullYear()}, attendanceList, null, () => {
      // attendanceList.value = attendanceList.value.map(x => ({...x, datePart: timestampToDatePart(x.regDt)}));
      // isLoading.value = false;
      let month = new Date().getMonth() + 1;
      attendanceCnt.value = attendanceList.value.filter(x => x.cmptnMonth === month).length;

    });

    const tmpKeyword = ref("");

    const searchKeyword = async (keyword) => {
      if (keyword.length === 0) {
        showMessage("검색어를 입력 바랍니다.");
      } else {
        console.log("keyword = ", keyword);
        isSearch.value = true;
        if (search.keyword.length > 0) {
          activeIdx.value = -2;
        }
        hrdLoading.value = true;
        sslLoading.value = true;
        hottipLoading.value = true;
        tmpKeyword.value = keyword;
        paging.pageNo = 1;
        sslPaging.pageNo = 1;
        shortsPaging.pageNo = 1;
        hrdCloudItems.value = [];
        sslitems.value = [];
        hrdShorts.value = [];
        isSearchEnd.value = false;
        store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`,
            {keyword: keyword, pageSize: paging.pageSize}).then(res => {
          if (lengthCheck(res)) {
            hrdCloudItems.value = getItems(res);
          } else {
            hrdCloudItems.value = [];
          }
          hrdLoading.value = false;
        });

        store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`,
            {keyword: keyword, pageSize: paging.pageSize, picRate: '9.16'}).then(res => {
          if (lengthCheck(res)) {
            hrdShorts.value = getItems(res);
          } else {
            hrdShorts.value = [];
          }
        });

        store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`,
            {
              lrnTrgtGrpMstSns: lrnTrgtGrpMstSns.value.join(','),
              pageSize: paging.pageSize,
              keyword: keyword
            }).then(res => {
          if (lengthCheck(res)) {
            sslitems.value = getItems(res);
          } else {
            sslitems.value = [];
          }
          sslLoading.value = false;
        });

        await getPostList({
          word: keyword,
          boardMstSn: boardMst.boardMstSn,
          topFixYn: 'N',
          pageSize: 5
        }, hotTipMyPreviewList, null, boardCateList);
        hottipLoading.value = false;
      }
    }

    const toggleModal = {
      openSubscribe: () => {
        subscriptionModal.value = true;
      }
    };


    let nowDate = new Date();
    const expirationPopup = computed(() => parseInt(localStorage.getItem('kb-popup-main')) > nowDate);

    const sslCardFilter = ref('recommend');

    const excellentItems = ref([]);
    const subscribeItems = ref([]);
    const sslRecommendItems = ref([]);

    const getsslCardList = async (sslCardFilter) => {
      sslLoading.value = true;
      if (sslCardFilter.value === 'excellent') {
        if (!stringCheck(excellentItems.value)) {
          await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
            if(lengthCheck(res)) {
              // console.log("res.value");
              // console.log(getItems(res));
              excellentItems.value = getItems(res).map(x => ({
                ...x,
                date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
                day: timestampToDateFormat(x.pblcnDt, 'dd'),
                distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
              }));
              // console.log("sslitems.value");
              // console.log(sslitems.value);
            }
          });
        }
        sslitems.value = excellentItems.value;
        sslLoading.value = false;
      } else if (sslCardFilter.value === 'recommend') {
        sslRecommendItems.value = [];
        await store.dispatch(`ssl/${ACT_GET_SOC_RECPOP_BOARD_LIST}`, {seqNm: '0'}).then(res => {
          if (lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            sslRecommendItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("sslitems.value");
            // console.log(sslitems.value);
          }
        });
        // if (sslRecommendItems.value == null || sslRecommendItems.value.length == 0) {
        //   sslCardFilter.value = 'excellent';
        // } else {
        //   sslitems.value = sslRecommendItems.value;
        // }
        sslitems.value = sslRecommendItems.value;
        sslLoading.value = false;
      }else if(sslCardFilter.value == 'subscribed'){

        sslLoading.value = true;
        subscribeItems.value = [];
        store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
          if(lengthCheck(res)) {
            // console.log("res.value");
            // console.log(getItems(res));
            subscribeItems.value = getItems(res).map(x => ({
              ...x,
              date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
              day: timestampToDateFormat(x.pblcnDt, 'dd'),
              distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
            }));
            // console.log("subscribe.value");
            // console.log(sslitems.value);
            subscribeCnt.value = subscribeItems.value.length;
            sslitems.value = subscribeItems.value;
            sslLoading.value = false;
          }else{
            subscribeCnt.value = 0;
            getBestSubscribesNoteList();
          }
        });
      }
    };
    getsslCardList(sslCardFilter);

    // const sslSubscLoading = ref(false);

    const subscribeCnt = ref(0);
    // const getsslSubscribeCardList =()=>{
    //
    //   sslSubscLoading.value = true;
    //   if (!stringCheck(subscribeItems.value)) {
    //     store.dispatch(`ssl/${ACT_GET_SOC_SUBSCRIBE_BOARD_LIST}`).then(res => {
    //       if(lengthCheck(res)) {
    //         // console.log("res.value");
    //         // console.log(getItems(res));
    //         subscribeItems.value = getItems(res).map(x => ({
    //           ...x,
    //           date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
    //           day: timestampToDateFormat(x.pblcnDt, 'dd'),
    //           distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
    //         }));
    //         // console.log("subscribe.value");
    //         // console.log(sslitems.value);
    //         subscribeCnt.value = subscribeItems.value.length;
    //       }else{
    //         subscribeCnt.value = 0;
    //         getBestSubscribesNoteList();
    //       }
    //     });
    //   }
    //   sslSubscLoading.value = false;
    // }
    // getsslSubscribeCardList();

    const getBestSubscribesNoteList=()=>{
      sslLoading.value = true;
      store.dispatch(`ssl/${ACT_GET_BEST_SUBSCRIBES_NOTE_LIST}`).then(res => {
        if(lengthCheck(res)) {
          // console.log("res.value");
          // console.log(getItems(res));
          subscribeItems.value = getItems(res).map(x => ({
            ...x,
            date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
            day: timestampToDateFormat(x.pblcnDt, 'dd'),
            distCrseCd: getCrseSnToDistCrseCd(x.crseMstSn)
          }));
          // console.log("subscribe.value");
          // console.log(sslitems.value);
        }
      });
      sslitems.value = subscribeItems.value;
      sslLoading.value = false;
    }



    watch(() => sslCardFilter.value, () => {
      getsslCardList(sslCardFilter);
    });


    getHrdCloudLatestList();
    getHotTipList('hottip-my');
    const pagingFunc = (pageNo) => {
      paging.pageNo = pageNo;
      if (pageNo) {
        pushQuery({...search, pageNo});
      } else {
        pushQuery({...search});
      }
    };

    const handleMenuClick = (tabMenu) => {
      hrds.value = [];
      // initParams(search);
      search.sortTyCdDcd = sortTyCdDcds.SORT_TY_CD_LATEST;
      if (tabMenu === 1) {
        search.category1 = 1;
      } else if (tabMenu === 2) {
        search.category1 = 2;
      } else if (tabMenu === 3) {
        search.category1 = 3;
      } else if (tabMenu === 4) {
        search.category1 = 4;
      } else if (tabMenu === 5) {
        search.category1 = 5;
      }
      pagingFunc(1);
    };

    const searchFunc = async () => {
      await getList();
    };

    const renderTitle = computed(() => {
      if (tabMenu.value > 0) return getCateNm(search.category1);
      else return view.title;
    });


    // const scrollFunction = () => {
    //   showSubMenu.value=false;
    //   showHrdCloudCate.value=false;
    //   const curr = window.pageYOffset;
    //   if (prev.value >= curr) showRoute.value=true
    //   else showRoute.value=false;
    //   prev.value = curr;
    // };


    // getMainList();

    const subscribeNotiPopupModal = ref(true);
    const eventStatusPopup = ref(true);

    const checkEventFlag = () => {
      return dateToDateFormat(new Date(), 'yyyyMMdd') <= '20231130';
    }

    eventStatusPopup.value = checkEventFlag();

    watch(() => tabMenu.value, () => {
      subscribeNotiPopupModal.value = tabMenu.value === 0;
      eventStatusPopup.value = tabMenu.value === 0 ? checkEventFlag() : false;
    });

    const addDefaultHrdCloudList = async () => {
      const res = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_LATEST_LIST}`, {...paging});
      if (lengthCheck(res)) {
        hrdCloudItems.value = hrdCloudItems.value.concat(getItems(res));
      } else {
        isSearchEnd.value = true;
      }
      scrollLoading.value = false;
    };

    const addSearchItems = async (keyword) => {
      tmpKeyword.value = keyword;
      store.dispatch(`prep/${ACT_GET_HRDCLOUD_LIST}`,
          {keyword: keyword, pageSize: paging.pageSize, pageNo: paging.pageNo}).then(res => {
        if (lengthCheck(res)) {
          hrdCloudItems.value = hrdCloudItems.value.concat(getItems(res));
        } else {
          isSearchEnd.value = true;
        }
        scrollLoading.value = false;
      });
    }

    onscroll = (event) => {
      if (event.target.getElementById('kb-hrdcloud-v2') && window.scrollY + window.innerHeight >= (event.target.getElementById('kb-hrdcloud-v2').scrollHeight * 0.9) && !scrollLoading.value && !isSearchEnd.value) {
        scrollLoading.value = true;
        paging.pageNo++;

        if (isSearch.value) {
          if (activeIdx.value === -1) {
            addSearchItems(interestInfoNms.value.replaceAll(/[/,]/g, '|'));
          } else if (activeIdx.value === -2) {
            addSearchItems(search.keyword);
          } else {
            addSearchItems(interestInfoItems.value.filter(x => x.useYn === 'Y')[activeIdx.value].interestInfoNm);
          }
          return;
        } else {
          addDefaultHrdCloudList();
        }
      }
    }

    const addSSLItems = async () => {
      if (isSearch.value) {
        sslSwipeLoading.value = true;
        let keyword = '';

        if (activeIdx.value === -1) {
          keyword = interestInfoNms.value.replaceAll(/[/,]/g, '|');
        } else if (activeIdx.value === -2) {
          keyword = search.keyword;
        } else {
          keyword = interestRecmdInfoItems.value[activeIdx.value].interestInfoNm;
        }
        sslPaging.pageNo++;
        await store.dispatch(`ssl/${ACT_GET_SOC_BOARD_LIST}`,
            {
              lrnTrgtGrpMstSns: lrnTrgtGrpMstSns.value.join(','),
              pageSize: sslPaging.pageSize,
              pageNo: sslPaging.pageNo,
              keyword: keyword
            }).then(res => {
          if (lengthCheck(res)) {
            sslitems.value = sslitems.value.concat(getItems(res));
          }
          sslLoading.value = false;
          sslSwipeLoading.value = false;
        });
      } else {
        // 추후 추가 예정
        return;
        // await store.dispatch(`ssl/${ACT_GET_SOC_EXCELLENT_BOARD_LIST}`).then(res => {
        //   if (lengthCheck(res)) {
        //     excellentItems.value = getItems(res).map(x => ({
        //       ...x,
        //       date: timestampToDateFormat(x.pblcnDt, 'yyyy.MM'),
        //       day: timestampToDateFormat(x.pblcnDt, 'dd'),
        //       distCrseCd: getDistCrseCd(x.distCrseSn)
        //     }));
        //   }
        // });
      }
    }

    const addShortsItems = async () => {
      shortsSwipeLoading.value = true;
      shortsPaging.pageNo++;
      let shortsList = await getHrdCloudList({...search, ...shortsPaging, picRate: '9.16'});
      // hrds.value = getCheckItems(contentsList);
      hrdShorts.value = hrdShorts.value.concat(getCheckItems(shortsList));
      shortsSwipeLoading.value = false;
    };


    const getShortsList = async () => {
      console.log("Paging ====", paging);
      let shortsContentsList = await getHrdCloudList({picRate: '9.16', ...paging});
      hrdShorts.value = getCheckItems(shortsContentsList);
      console.log("hrdShorts.value ", hrdShorts.value);
    }

    const allScheduledDcds = [lrnObjTyCds.Evaluation, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudNowLive, lrnObjTyCds.CloudOn, lrnObjTyCds.Survey, lrnObjTyCds.LearningTime];
    const scheduledDcds = [lrnObjTyCds.Evaluation, lrnObjTyCds.CloudNow, lrnObjTyCds.CloudOn];

    const todayTime = dateToDateFormat(new Date(), 'yyyyMMddhhmm');
    const currentYmd = dateToDatePart(new Date()).ymd;

    const getLearnObjectScheduleList = () => {
      store.dispatch(`learn/${ACT_GET_LEARN_OBJECT_TODAY_LIST}`, {objTargetYmd: getTodayDate('yyyy-mm-dd')}).then(res => {
        if (lengthCheck(res)) {
          schedules.value = getItems(res).filter(x => (isKbBank.value ? allScheduledDcds : scheduledDcds).includes(x.lrnObjTyCd))
              .map(x => ({
                ...x,
                objBgngYmd: timestampToDatePart(x.objBgngDt).ymd,
                objEndYmd: timestampToDatePart(x.objEndDt).ymd,
                objBgngDt: timestampToDateFormat(x.objBgngDt, 'yyyyMMddhhmm'),
                objEndDt: timestampToDateFormat(x.objEndDt, 'yyyyMMddhhmm'),
                lrnTime: (timestampToDateFormat(x.objEndDt, 'yyyyMMddhhmm') < todayTime || x.objEndYn === 'Y' ? '종료' : `${timestampToDateFormat(x.objBgngDt, 'hh:mm')}~${timestampToDateFormat(x.objEndDt, 'hh:mm')}`),
                // lrnTime:  `${timestampToDateFormat(x.objBgngDt, 'hh:mm')}~${timestampToDateFormat(x.objEndDt, 'hh:mm')}`,
                lrnEnd: (timestampToDateFormat(x.objEndDt, 'yyyyMMddhhmm') < todayTime) ? 'Y' : 'N'
              }))
              .filter(x => (x.objBgngYmd === x.objEndYmd)) //  '객체 노출 타입은 평가, 비대면집합(NOW)만 노출'
              .sort((a, b) => {
                if (a.lrnEnd === 'Y') return 1;
                else if (a.objBgngDt - b.objBgngDt) return -1;
                return 0;
              })
        } else {
          schedules.value = [];
        }
        console.log("schedules.value!!", schedules.value);
      })
    };

    const clickMove = (item) => {
      console.log(item);
      if(currentYmd === item.objBgngYmd){
        if (item.splCrseTyCdDcd === '2066010') {
          router.push({
            name: 'KnowledgeELearning',
            params: {distCrseSn: item.distCrseSn, view: 'index'},
            query: {learn: item.lrnObjDtlDistSn}
          });
        } else {
          if (item.lrnObjTyCd === lrnObjTyCds.CloudNow || item.lrnObjTyCd === lrnObjTyCds.CloudNowLive) {
            router.push({name: 'LiveCalendarMng'})
          } else if (item.lrnObjTyCd === lrnObjTyCds.CloudOn) {
            router.push({name: 'GroupView', params: {distCrseSn: item.distCrseSn}});
          }else if(item.lrnObjTyCd ===  lrnObjTyCds.LearningTime){
            router.push({name: 'HrdCloudContentBridge', params: {lrnObjDtlDistSn: item.lrnObjDtlDistSn, mode: 'detail'}});
          } else {
            router.push({
              name: 'LearnMain',
              params: {distCrseSn: item.distCrseSn, view: 'index'},
              query: {learn: item.lrnObjDtlDistSn}
            });
          }
        }
      } else {
        router.push({name: 'LearnMain', params:{ distCrseSn: item.distCrseSn, view: 'home' }});
      }
    }

    getLearnObjectScheduleList();

    getShortsList();

    const popups = ref([]);
    // 팝업
    getBanerList({
      banerDivCdDcd: banerDivCdDcds.popup,
      banerExpsrLocCdDcd: banerExpsrLocCdDcds.main,
      pageNo: 1,
      pageSize: 10
    }, popups);

    return {
      isSearching,
      isLoading,
      view,
      session,
      search,
      tabMenu,
      toggles,
      hrds,
      paging,
      toggleModal,
      categories,
      lochref,
      modalActive,
      subscriptionModal,
      addModal,
      showSubMenu,
      showHrdCloudMap,
      showRoute,
      showHrdCloudCate,
      categoriesPaging,
      pagingFunc,
      handleActions,
      searchFunc,
      handleMenuClick,
      sortTyCdDcd,
      getCateNm,
      renderTitle,
      popups,
      expirationPopup,
      showKbTubeModal,
      subscribeNotiPopupModal,
      eventStatusPopup,
      roadmap,
      activeIdx,
      getTodayDate,
      isKbBank,
      sslList,
      sslLoading,
      sslitems,
      interestInfoItems,
      interestRecmdInfoItems,
      getInterestRecmdInfoList,
      interestInfoNms,
      showInterestInfoModal,
      topicSelected,
      sslCardFilter,
      hrdCloudItems,
      hrdLoading,
      moveSSL,
      searchKeyword,
      clickInterestSearch,
      hottipLoading,
      hotTipMyPreviewList,
      moveToBoardDtl,
      isSearch,
      attendanceCnt,
      attendanceModal,
      tmpKeyword,
      resetSearch,
      scrollLoading,
      sslSwipeLoading,
      shortsSwipeLoading,
      addSSLItems,
      hrdShorts,
      addShortsItems,
      schedules,
      clickMove,
      // sslSubscLoading,
      // subscribeItems
    }
  }
};
</script>
